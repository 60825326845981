import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from './sagas';

import auth from './redux/reducers/auth';
import home from './redux/reducers/home';
import users from './redux/reducers/users';
import merchants from './redux/reducers/merchants';
import query from './redux/reducers/query';

const sagaMiddleware = createSagaMiddleware();
export default createStore(combineReducers({
    auth,
    home,
    merchants,
    users,
    query
}), composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { decode }          from 'jsonwebtoken';

import history from './../../utils/history';

import store               from '../../store';
import { HOME_ACTIONS, LOGIN_ACTIONS, USERS_ACTIONS } from '../../constants/actions';
import { getSessionStatus } from './../../services/services'

export function hasPermissionTo (permissions: string[], user: any) {
    return permissions.every(permission => user.permissions.includes(permission));
}

export function ProtectedRoute (path, component, user, permission) {
    if (!user || !isAuthenticated() || !hasPermissionTo(permission, user)) {
        return <Redirect path={path} to={'/login'}/>;
    }

    return <Route path={path} component={component}/>;
}

export function isAuthenticated () {
    return !!localStorage.getItem('token');
}

export function setUserInStore (user) {
    store.dispatch({
        type   : 'SET_USER',
        payload: user
    });
}

export function getUserFromToken () {
    const token = decode(localStorage.getItem('token'));

    return token ? token.user : null;
}

export function logout () {
    store.dispatch({
        type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGOUT
    });

    history.push('/login');
    window.location.reload();
}

export function checkUserToken() {
    const user = getUserFromToken();

    if (getUserFromToken()) {
        setUserInStore(user)
    }
}

export function getInfoUserSession() {
  // console.log(store.getState().auth['userResponseByCode']['user']['id_user']);
  const id_user = store.getState().auth['userResponseByCode']['user']['id_user'];
  const acquirerSelected = localStorage.getItem("acquirer-key");
  store.dispatch({
    type: HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE,
    token: localStorage.getItem('token'),
    id: id_user,
    withOutAcquirerKey: acquirerSelected
  });
}
import { HOME_ACTIONS, MERCHANTS_ACTIONS } from "../../constants/actions"

export const updateList = (list) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_UPDATE_QUERIES,
    list
  }
}

export const getListBrands = (id, token, acquirerSelected) => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER,
      id,
      token,
      acquirerSelected
  }
}

export const receiveResponseListBrands = (response) => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER,
      response
  }
}

export const getListCountries = () => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER
  }
}

export const receiveResponseListCountries = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES,
    response
  }
}

export const listAcquirersHome = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING,
  }
}

export const listAcquirersHomeLoaded = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADED,
  }
}

export const resetBrandsMerchants = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RESET_BRANDS,
  }
}

export const selectAcquirerForDashboard = (acquirer) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_SELECT_ACQUIRER_DASHBOARD,
    acquirer
  }
}

export const getUserActive = (token, id, withOutAcquirerKey) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE,
    token,
    id,
    withOutAcquirerKey
  }
}


export const handleValuesUpdateInfoUserUpdateInfoUI = (id, value) => {
  return {
    type: HOME_ACTIONS.HOME_ACTION_HANDLE_UPDATE_INFO_USER_UI,
    id,
    value
  }
}

export const receiveResponseGetUserActive = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE,
    response
  }
}

export const updateAcquirerPictureUi = (token, acquirerSelected, idUser, base64) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER,
    token,
    acquirerSelected,
    idUser,
    base64
  }
}

export const receiveResponseUpdateAcquirerPictureUi = (response) => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER,
    response
  }
}

export const updateUserInfoProfile =(token, acquirerSelected, user, user_key) => {
  return {
      type: HOME_ACTIONS.HOME_ACTION_UPDATE_USER_INFO_PROFILE,
      token,
      acquirerSelected,
      user,
      user_key
  }
}

export const receiveResponseUpdateUserInfoProfile = (response) => {
  return {
      type: HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO_PROFILE,
      response
  }
}

export const resetvaluesprofilechange = () => {
  return {
    type: HOME_ACTIONS.HOME_ACTIONS_RESET_PROFILE_RESET_VALUES
  }
}

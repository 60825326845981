import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useEffect }           from 'react';
import ReactDOM        from 'react-dom';
import { Provider }    from 'react-redux';

import App   from './App';
import store from './store';

import * as serviceWorker from './serviceWorker';

import './index.css';
import 'antd/dist/antd.css';

import Transform from './utils/transform';
import GA4React from 'ga-4-react';

const result = Transform.changeRoutesToProd()

const GACODE = result.analytics_key;
const ga4react = new GA4React(GACODE, {debug_mode: true, send_page_view: true});
ga4react.initialize();

export const GApageView = (page: string) => { 
  document.title = "Alignet | " + page;
}

ReactDOM.render(
  // <AnalyticsProvider instance={analytics}>
    <Provider store={store}>
        <App/>
    </Provider>
    // </AnalyticsProvider>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();

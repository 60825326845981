import { ROUTE_GET } from '../constants/constants';
import { GET_COMMENTS_BY_ID, LIST_MERCHANTS, LIST_BRANDS, LIST_COUNTRIES, POST_UPLOAD_FILE, GET_SENT_ALL_FILTERS, GET_MERCHANT, EDIT_MERCHANT, GET_LIST_TRANSACTIONS, GET_URL_DOWNLOAD_FILE, GET_FILE, GET_INFO_TRANSACTION, LIST_ACQUIRERS, LIST_USERS, GENERATE_INVITATION, GET_INFO_USER, RESENT_CODE, GET_ASSOCIATE_DETAIL, UPDATE_USER_INFO, UPDATE_USER_IMAGE, DELETE_USER, CANCEL_INVITATION, RESENT_INVITATION, PUT_ACQUIRER_IMAGE, SENT_FILE_TO_SERVER, PUT_PROCESS_FILE_ROUTE } from '../constants/routes';
import Transform from '../utils/transform';

const result = Transform.changeRoutesToProd()
// export default class SecureKeyServices {
export const getData = async () => {
  try {
    const response = await fetch(ROUTE_GET);
    const data = response.json();
    console.log(data)
    return data;
  }
  catch (error) {
    console.log('error:', error)
  }
}

export const getCommentsById = async (id) => {
  try {
    const response = await fetch(GET_COMMENTS_BY_ID(id));
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

// SESSION STATUS
export const getSessionStatus = async (token) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        'Authorization': `${token}`,
        'Content-type': 'application/json'
      },
      mode: 'cors'
    }

    const response = await fetch(result.get_session_status, options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

// AUTH

export const getAuthorizationUser = async (user) => {
  try {
    const options = { method: 'POST', body: JSON.stringify(user), mode: 'cors' };
    const response = await fetch(result.auth_user, options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getAuthorizationByCode = async (token) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json'
      },
      mode: 'cors'
    }

    const response = await fetch(result.get_auth_user, options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putAuthorizationByCode = async (token, code) => {
  try {
    const body = {
      "action": "validate_auth",
      "validation_code": code
    }
    console.log(token)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    console.log(JSON.stringify(body))
    const response = await fetch(result.put_auth_user, options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postRecoveryPasswordUser = async (email) => {
  const body = {
    "action": "str",
    "email": email
  }
  try {
    const options = { method: 'POST', body: JSON.stringify(body), mode: 'cors' };
    const response = await fetch(result.post_auth_user, options)
    const data = response.json();
    console.log(data)
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postListAllMerchantsFiltereds = async (filtersFields, token, page, acquirerSelected, filterSelectedToDelete) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-type': 'application/json',
        'Acquirer-Key': acquirerSelected
      },
      mode: 'cors'
    };

    const response = await fetch(LIST_MERCHANTS(filtersFields, page, filterSelectedToDelete), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postRegisterMerchant = async (body, token, acquirerSelected) => {
  console.log(body)
  try {
    const options = {
      method: 'POST',
      headers: {
        Authorization: token,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(result.register_merchant, options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListBrands = async (id, token) => {
  // console.log(id)
  // console.log(token)
  try {
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        // 'Acquirer-Key': 'FDZ2HrZ4qa0cVLNA.yyLI82d8nPkCxK1jrO4UfzcIxsIqVwPpyhX90qXaiRc9jeOnvvVjViyl010ut20x',
        'Content-type': 'application/json',
      },
      // body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(LIST_BRANDS(id), options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListCountries = async () => {
  try {
    const options = {
      method: 'GET',
      headers: {
        Authorization: 'A2WkQmwaXrPE7eFyHf4R74HS',
        // 'Acquirer-Key': 'bFHyYuMMxrAqa2Ua9ADfRRPwqSeEL4Ny',
        'Content-type': 'application/json',
      },
      // body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(LIST_COUNTRIES, options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllTransactionsFiltereds = async (filters, token, page, acquirerSelected, filterSelectedToDelete) => {
  // console.log(filters)
  // console.log(token)
  try {
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const response = await fetch(GET_LIST_TRANSACTIONS(filters, page, filterSelectedToDelete), options)
    let data = response.json();
    console.log(data)
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

// EXPORT TRANSACTIONS
export const exportAllTransactionsFiltereds = async (filters, token, acquirerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const r = await fetch(GET_LIST_TRANSACTIONS(filters, 0, null, true), options).then(response => {
      if (response.status === 400) {
        return response.json().then(
          data => {
            console.log(data);
            return {
              success: false,
              message: data.meta.status.message_ilgn[0].value 
            }
          }
        );
      } else {
        if (response.status !== 200) {
          return {
            success: false,
            message: 'Ocurrió un problema'
          }
        } else {
          const filename =  response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
          console.log(filename);
          response.blob().then(blob => download(blob, filename));
          return {
            success: true,
            message: 'Descarga correcta'
          }
        }
      }
    });
    return r;
  }
  catch (e) {
    console.log('error:', e)
  }  
}

// EXPORTS MERCHANTS
export const exportAllMerchantsFiltereds = async (filters, token, acquirerSelected) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const r = await fetch(LIST_MERCHANTS(filters, 0, null, true), options).then(response => {
      if (response.status === 400) {
        return response.json().then(
          data => {
            console.log(data);
            return {
              success: false,
              message: data.meta.status.message_ilgn[0].value 
            }
          }
        );
      } else {
        if (response.status !== 200) {
          return {
            success: false,
            message: 'Ocurrió un problema'
          }
        } else {
          const filename =  response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
          console.log(filename);
          response.blob().then(blob => download(blob, filename));
          return {
            success: true,
            message: 'Descarga correcta'
          }
        }
      }
    });
    return r;
  }
  catch (e) {
    console.log('error:', e)
  }  
}

// BULK LOAD
export const postSentAllFiltersList = async (filters, token, acquirerSelected, page) => {
  let dateStart = '';
  let dateEnd = '';
  let name = '';
  let processBatch = '';
  let stateBatch = '';
  console.log(filters)
  const bodyAux = {
    "action": "list_batch",
    "filters": (filters && filters.length > 0) ? filters : []
  }
  try {
    // if (bodyAux && bodyAux.filters.length) {
    console.log(bodyAux)
    bodyAux.filters.forEach(element => {
      if (element.name === 'date_start' && element.value !== '') dateStart = element.value;
      if (element.name === 'date_end' && element.value !== '') dateEnd = element.value;
      if (element.name === 'name_upload' && element.value !== '') name = element.value;
      if (element.name === 'state_process' && element.value !== '') processBatch = element.value;
      if (element.name === 'state_batch' && element.value !== '') stateBatch = element.value;
    })
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Acquirer-Key': acquirerSelected,
        // 'Acquirer-Key': 'FDZ2HrZ4qa0cVLNA.yyLI82d8nPkCxK1jrO4UfzcIxsIqVwPpyhX90qXaiRc9jeOnvvVjViyl010ut20x',

        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    console.log(dateStart)
    console.log(dateEnd)
    console.log(name)
    console.log(processBatch)
    console.log(stateBatch)
    const response = await fetch(GET_SENT_ALL_FILTERS(dateStart, dateEnd, name, processBatch, stateBatch, page), options)
    const data = response.json();
    console.log(data)

    return data;
    // }
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getMerchantInfoByCode = async (code, token, acquirer_id) => {
  try {
    console.log(code)
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Acquirer-Key': acquirer_id,
        'Content-type': 'application/json',
      },
      mode: 'cors'
    };
    const response = await fetch(GET_MERCHANT(code), options)
    const data = response.json();
    console.log(data)
    return data;
  }
  catch (e) {
    console.log(e, 'ERROR')
  }
}

export const putEditMerchant = async (body, token, code, acquirerSelected) => {
  const aux = { ...body }
  console.log(token)
  body.action = "update_merchant"
  console.log(body)
  aux.brands = aux.brands.map(a => {
    a.currencies = a.currencies.map(e => {
      if (e && e.description) delete e.description
      return e;
    })
    return a
  })
  // let aux2 = null 
  try {
    // aux2.brands = aux.brands
    console.log(aux)
    console.log(body)
    const options = {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(aux),
      mode: 'cors'
    };
    const response = await fetch(EDIT_MERCHANT(code), options)
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getUrlUploadFiled = async (token, acquirerSelected, nameFile) => {
  console.log(token)
  try {
    const options = {
      method: 'POST',
      headers: {
        Authorization: token,
        'Name-file': nameFile,
        'Acquirer-Key': acquirerSelected,
        'Content-type': 'application/json',
      },
      // body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(result.get_url_upload_file, options)
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const postUploadFile = async (body) => {
  console.log(body)
  try {
    const options = {
      method: 'POST',
      headers: {
        // Authorization: 'A2WkQmwaXrPE7eFyHf4R74HS',
        // 'Acquirer-Key': 'bFHyYuMMxrAqa2Ua9ADfRRPwqSeEL4Ny',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'cors'
    };
    const response = await fetch(POST_UPLOAD_FILE, options)
    const data = response.json();

    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

// export const postSentFileToServer = async (url, body, result, token, fileUploaded) => {
export const postSentFileToServer = async (token, file, fields, acquirerSelected, nameFile) => {
  console.log(fields)
  const formData = new FormData();
  formData.append('file', file)

  try {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'Acquirer-Key': acquirerSelected,
        'Name-file': nameFile,

        'Accept': 'multipart/form-data',
        'Merchant-Batch-Id': fields.merchant_batch_id,
        // 'Id-Acquirer': fields.id_acquirer,
        'Upload-Name-Batch': fields.upload_name_batch,
        'Generate-Name-Batch': fields.generate_name_batch,
        'Id-Aux': fields.user_id,
        'Date-Time-Upload-Batch': fields.date_time_upload_batch,
      },
      body: formData,
      mode: 'cors'
    };
    console.log(options)
    const response = await fetch(SENT_FILE_TO_SERVER(), options)
    // const response = await fetch(url, options)
    console.log(response)
    const data = response
    console.log(data.ok, "DATA RESPONSE AMAZON UPLOAD FILE")
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putProcessFile = async (token, id) => {
  try {
    console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        'Acquirer-Key': 'bFHyYuMMxrAqa2Ua9ADfRRPwqSeEL4Ny',
        'Content-type': 'application/json',
        'merchant_batch_id': id,
        'input_type': 'web_type'
      },
      //  body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(PUT_PROCESS_FILE_ROUTE(), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getUrl = async (id, token, acquirerSelected) => {
  try {
    console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json',
        'Acquirer-Key': acquirerSelected
        //  'Acquirer-Key': 'FDZ2HrZ4qa0cVLNA.yyLI82d8nPkCxK1jrO4UfzcIxsIqVwPpyhX90qXaiRc9jeOnvvVjViyl010ut20x'

      },
      //  body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(GET_URL_DOWNLOAD_FILE(id), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getFile = async (token) => {
  try {
    const body = {
      "action": "validate_auth",
    }
    console.log(token)
    const options = {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(GET_FILE, options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getTransaction = async (id, token, acquirerSelected) => {
  try {
    console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        //  Authorization: '82If0FMPh9eH7nLrmHI80wjJxrKo73KU1UCmVIDlQ1Hlzuf0LCMW6emajp7g1pQFO2plcxJ5uUIB37fE',
        'Content-type': 'application/json',
        'Acquirer-Key': acquirerSelected
      },
      //  body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(GET_INFO_TRANSACTION(id), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getAcquirers = async (token) => {
  try {
    // console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
      mode: 'cors',
    }
    const response = await fetch(LIST_ACQUIRERS(), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getListAllUsers = async (token, acquirerSelected, pageCurrentDashboard, objUsersFiltered) => {
  try {
    console.log(token)
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        'Acquirer-Key': acquirerSelected
      },
      mode: 'cors',
    }
    const response = await fetch(LIST_USERS(objUsersFiltered, pageCurrentDashboard), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getUserInfoService = async (token, idUser, acquirerSelected, withOutAcquirerKey) => {
  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        //  'Acquirer-Key': acquirerSelected
      },
      mode: 'cors',
    }
    if (acquirerSelected) options.headers['Acquirer-Key'] = acquirerSelected;
    const response = await fetch(GET_INFO_USER(idUser), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const sentInvitationApi = async (token, acquirerSelected, newUser) => {
  try {
    if (newUser && newUser.email && !newUser.role) newUser.role = "Administrador"
    const options = {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        'Content-type': 'application/json',
        'Acquirer-Key': acquirerSelected
      },
      body: JSON.stringify(newUser),
      mode: 'cors',
    }
    const response = await fetch(GENERATE_INVITATION(), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const reSentCode = async (email) => {
  try {
    const body = {
      email: email
    }
    const options = {
      method: "POST",
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(RESENT_CODE(), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const getAssociateDetailUser = async (id, pageCurrent, token) => {
  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        //  'Acquirer-Key': acquirerSelected
      },
      mode: 'cors',
    }
    const response = await fetch(GET_ASSOCIATE_DETAIL(id, pageCurrent), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfoPassword = async (token, acquirerSelected, user, user_key) => {
  try {
    const body = {
      "action": "change_pass",
      "passw": user.current,
      "passw_new": user.newPassword,
      "passw_renew": user.againPassword
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    const response = await fetch(UPDATE_USER_INFO(user_key), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfoProfile = async (token, acquirerSelected, user, user_key) => {
  try {
    const body = {
      "action": "update_profile",
      "firstName": user.name,
      "lastName": user.lastname,
      "type_country": user.countryCode,
      "country": user.country,
      "phone": user.phone
    }
    console.log(body)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(UPDATE_USER_INFO(user_key), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUpdateUserInfo = async (token, acquirerSelected, user) => {
  try {
    const body = {
      "action": "update_user",
      "firstName": user.name,
      "lastName": user.lastname,
      "type_country": user.type_country,
      "country": user.country,
      "phone": user.mobile,
      "role": user.role,
      "state": user.state
    }
    console.log(user)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(UPDATE_USER_INFO(user.user_key), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const putUserImage = async (token, acquirerSelected, base64, idUser) => {
  try {
    const body = {
      "action": "update_image",
      "image": base64
    }
    console.log(idUser)
    console.log(base64)
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(UPDATE_USER_IMAGE(idUser), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const deleteUser = async (token, acquirerSelected, idUser) => {
  try {
    const body = {
      "action": "delete_user"
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(DELETE_USER(idUser), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const cancelInvitationApi = async (token, acquirerSelected, idUser) => {
  try {
    const body = {
      "action": "cancel_invitation"
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(CANCEL_INVITATION(idUser), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const reSentInvitationApi = async (token, acquirerSelected, idUser, role) => {
  try {
    const body = {
      "action": "send_invitation",
      "role": role
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        // 'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    //  console.log(JSON.stringify(body))
    const response = await fetch(RESENT_INVITATION(idUser), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}

export const updateAcquirerPicture = async (token, acquirerSelected, idUser, base64) => {
  try {
    const body = {
      "action": "put_acquirer",
      "image": base64
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
        'Acquirer-key': acquirerSelected,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
      mode: 'cors',
    }
    console.log(options)
    const response = await fetch(PUT_ACQUIRER_IMAGE(acquirerSelected), options);
    const data = response.json();
    return data;
  }
  catch (e) {
    console.log('error:', e)
  }
}


// CHANGE ORDER LIST
// TRANSACTIONS

// MERCHANTS

// USERS

import { USERS_ACTIONS } from "../../constants/actions";
import { defaultValuesUsers } from "../../constants/constants";

let defaultState = defaultValuesUsers;

const users = (state = defaultState, action) => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES: {
      const aux = { ...state.objUsersFiltered }
      var result = /^[a-zA-Z]+$/.test(action.value)
      console.log(result)
      console.log(action)
      if (!result && action.value.length === 0) {
        aux[action.id] = ""
      }
      if (result) {
        if (action.id === "name")
          aux[action.id] = action.value

        if (action.id === "lastName")
          aux[action.id] = action.value

        if (action.id === "role")
          aux[action.id] = action.value

        if (action.id === "state")
          aux[action.id] = action.value
      }
      else {
        if (action.id === "fromDateUser" || action.id === "toDateUser")
          aux[action.id] = action.value
      }
      if (action.id === "email" || action.id === "state") aux[action.id] = action.value
      return {
        ...state,
        objUsersFiltered: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_LIST_USERS:
      return {
        ...state,
        changePageDashboard: false,
        allUserLoaded: false,
        responseListAllUsers: null
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS:
      return {
        ...state,
        responseListAllUsers: action.response,
        allUserLoaded: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_GET_INFO:
      return {
        ...state,
        isUpdatedUserInfo: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO: {
      console.log("USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFOXDDDDD");
      console.log(action)
      return {
        ...state,
        responseUserInfo: action.response,
        userInfoLoaded: true
      }

    }
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS: {
      const aux = { ...state.userInvited };
      aux[action.id] = action.value;
      console.log(aux)
      return {
        ...state,
        userInvited: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_LIST_USERS:
      return {
        ...state,
        listAllUsers: action.list,
      }
    case USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION:
      return {
        ...state,
        sendingInvitation: true,
        responseSentInvitation: null
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION:
      return {
        ...state,
        responseSentInvitation: action.response,
        sendingInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES: {
      const aux = {
        fromDateUser: '',
        toDateUser: '',
        name: '',
        lastName: '',
        email: '',
        role: '',
        state: ''
      }
      return {
        ...state,
        responseSentInvitation: null,
        objUsersFiltered: aux,
        allUserLoaded: false
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL:
      return {
        ...state,
        associateDetailIsloaded: true,
        changePage: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL:
      return {
        ...state,
        responseAssociateDetail: action.response,
        changePage: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_NEXT_PAGE:
      return {
        ...state,
        pageCurrentDashboard: state.pageCurrentDashboard + 1,
        changePageDashboard: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_BACK_PAGE:
      return {
        ...state,
        pageCurrentDashboard: state.pageCurrentDashboard - 1,
        changePageDashboard: true
      }
    case USERS_ACTIONS.USERS_ACTION_HANDLE_UPDATE_PASSWORD_UI:
      const aux = { ...state.userPasswordUI }
      aux[action.id] = action.value
      return {
        ...state,
        userPasswordUI: aux
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD:
      return {
        ...state,
        userPasswordUI: state.userPasswordUI,
        userPasswordChanging: true,
        userPasswordUpdated: false
      }

    case USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RESET_VALUES:
      return {
        ...state,
        userPasswordUI: state.userPasswordUI,
        userPasswordChanging: false,
        userPasswordUpdated: false
      }

    case USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RECEIVE:
      console.log(action.response);
      return {
        ...state,
        userPasswordUI: state.userPasswordUI,
        userPasswordChanging: false,
        userPasswordUpdated: true,
        userResult: (action.response && action.response.success) ? action.response.success : false,
        userResultMessage: (action.response && action.response.users.meta.status.message_ilgn) ? action.response.users.meta.status.message_ilgn[0].value : 'No se pudo actualizar su clave'
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RESET_VALUES_FORM:
      const aux_pass = {
        current: '',
        newPassword: '',
        againPassword: ''
      }
      return {
        ...state,
        userPasswordUI: aux_pass
      }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO:
      return {
        ...state,
        isUpdatedUserInfo: false,
        responseUpdatedUserInfo: null,
        isUpdatedUserInfoLoading: true

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO:
      return {
        ...state,
        responseUpdatedUserInfo: action.response,
        isUpdatedUserInfo: true,
        isUpdatedUserInfoLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_DELETE_USER:
      return {
        ...state,
        userIsDeleted: false,
        responseUserDelete: null,
        userIsDeletedLoading: true

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER:
      return {
        ...state,
        responseUserDelete: action.response,
        userIsDeleted: true,
        userIsDeletedLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE:
      return {
        ...state,
        responseUserImageUpdated: null,
        isUpdatedUserInfo: false,
        responseUserDelete: null,
        responseUpdatedUserInfo: null,
        associateDetailIsloaded: false,
        userInfoLoaded: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER: {
      const aux = { ...state.responseUserInfo }
      const aux2 = {}
      aux[action.id] = action.value

      return {
        ...state,
        newState: (action.id === 'state' || action.id === 'role') && state.responseUserInfo.state,
        responseUserInfo: aux,
        responseUpdatedUserInfo: null,
        userIsCancelInvitation: false,
        isChanging: true,
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE:
      return {
        ...state,
        userImageIsChanging: true,
        responseUserImageUpdated: null,
        userImageIsChanged: false,

      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE:
      return {
        ...state,
        responseUserImageUpdated: action.response,
        userImageIsChanging: false,
        userImageIsChanged: true
      }
    case USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION:
      return {
        ...state,
        responseUserCancelInvitation: null,
        userIsCancelInvitationLoading: true,
        userIsCancelInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION:
      return {
        ...state,
        responseUserCancelInvitation: action.response,
        userIsCancelInvitation: true,
        userIsCancelInvitationLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION:
      return {
        ...state,
        responseUserReSentInvitation: null,
        userIsReSentInvitationLoading: true,
        userIsReSentInvitation: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION:
      return {
        ...state,
        responseUserReSentInvitation: action.response,
        userIsReSentInvitation: true,
        userIsReSentInvitationLoading: false
      }
    case USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_ACTIVE: {
      const aux = { ...state.updateInfoUserUI }
      aux[action.id] = action.value
      return {
        ...state,
        updateInfoUserUI: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE: {
      console.log(action)
      console.log(state)
      let calculateNewPage;
      if (action.pageCurrentDashboard > state.pageCurrentDashboard) calculateNewPage = action.pageCurrentDashboard
      if (action.pageCurrentDashboard < state.pageCurrentDashboard) calculateNewPage = state.pageCurrentDashboard - action.pageCurrentDashboard
      console.log(calculateNewPage)
      return {
        ...state,
        // isChangedByButtonNumber: true,
        pageCurrent: action.pageCurrent, //calculateNewPage,
        changePage: true,
        // allUserLoaded: true
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_GO_TO_PAGE: {
      // console.log(action.pageCurrentDashboard);
      // console.log(state);
      // console.log(state.pageCurrentDashboard);
      let calculateNewPage;
      if (action.pageCurrentDashboard > state.pageCurrentDashboard) calculateNewPage = action.pageCurrentDashboard
      if (action.pageCurrentDashboard < state.pageCurrentDashboard) calculateNewPage = state.pageCurrentDashboard - action.pageCurrentDashboard
      console.log(calculateNewPage)
      return {
        ...state,
        isChangedByButtonNumber: true,
        pageCurrentDashboard: action.pageCurrentDashboard, //calculateNewPage,
        changePageDashboard: true,
        allUserLoaded: true
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_DELETE_FILTERS: {
      const aux = { ...state.objUsersFiltered };
      console.log(action)
      console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        objUsersFiltered: aux
      }
    }
    case USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.objUsersFiltered }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePageDashboard: false,
        // allUserLoaded: false,
        responseListAllUsers: null,
        objUsersFiltered: aux
      }
    }
    default:
      return state
  }
}

export default users;

import { HOME_ACTIONS, MERCHANTS_ACTIONS, TRANSACTION_ACTIONS } from "../../constants/actions";
import { defaultValuesQuery } from "../../constants/constants";
import Transform from "../../utils/transform";

let defaultState = defaultValuesQuery;

const query = (state = defaultState, action) => {
  switch (action.type) {
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_SENT_FILTERS_LIST_TRANSACTIONS: {
      let propertiesArray = []
      let newList = [];
      propertiesArray = Object.keys(state.objFiltered)
      propertiesArray.forEach(e => {
          if (state.objFiltered && state.objFiltered[e]) {
              newList.push(e);
          }
      })
      // console.log(newList)
      // console.log(state.objFiltered)
      return {
        ...state,
        searchingFilters: (newList && newList.length > 1) ? true : false,
        isSearching: true,
        transactionsList: null
      }
    }
    // EXPORT TRANSACTIONS
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_EXPORT_FILE: {
      return {
        ...state,
        exporting: true
      }
    }
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RECEIVE_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: action.response
      }
    }
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RESET_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: null
      }
    }


    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RECEIVE_RESPONSE_SENT_FILTERS_LIST_TRANSACTIONS: {
      console.log(action)
      return {
        ...state,
        responseTransactionsList: action.response,
        isLoadedTransactions: true,
        changePage: false,
        transactionsList: action.response.data,
        searchingFilters: false,
        isSearching: false
      }
    }
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GET_INFO:
      return {
        ...state
      }
    case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_RECEIVE_RESPONSE_GET_INFO:
      return {
        ...state,
        responseTransactionInfo: action.response,
        isLoadedGetInfoTransaction: true,
      }    
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_NEXT_PAGE:
        return {
          ...state,
          pageCurrent: state.pageCurrent + 1,
          changePage: true
        }
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_BACK_PAGE:
        return {
          ...state,
          pageCurrent: state.pageCurrent - 1,
          changePage: true
        }
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_FILTERS_RESET:
        return {
          ...state,
          objFiltered: {
            fromDateQuery: '',
            toDateQuery: '',
            transactionTypeQuery: '',
            messageVersionQuery:'',
            authResultQuery: '',
            nameQuery: '',
            codeQuery: '',
            numberOrderQuery: '',
            brandQuery: '',
            inclearTypeQuery: '',
            maskTypeQuery: 'Enmascarada',
            cardNumberQuery: '',
            binQuery: '',
            currencyQuery: '',
            eciQuery: '',
            vciQuery: '',
            transIdQuery: '',
            dsTransIdQuery: '',
            acsTransIdQuery: '',
          },
          responseTransactionsList:  null,
          transactionsList: null
        }
        case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_HANDLE_FIELDS_FILTEREDS: {
          const aux = { ...state.objFiltered };
          console.log(action)
          console.log(aux)
          
          if ((action.id === 'binQuery' && aux.binQuery.length === 16) && aux.maskTypeQuery === "Enmascarada") {
            const result = Transform.encryptNumber(Number(aux.binQuery))
            aux[action.id] = result
          } 
           else if (action.id === "maskTypeQuery" && aux.binQuery.length === 16)  {
             console.log("aquio")
             const result = Transform.encryptNumber(Number(aux.binQuery))
             aux.binQuery = result
           } 
          else {
            aux[action.id] = action.value;
          }
          return {
            ...state,
            objFiltered: aux
          }
        }
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_DELETE_FILTERS: {
        const aux = { ...state.objFiltered };
        console.log(action)
        console.log(aux)
        aux[action.propertyName] = ''
        return {
          ...state,
          objFiltered: aux
        }
      }
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GO_TO_PAGE: {
        let calculateNewPage;
        if (action.page > state.pageCurrent) calculateNewPage = action.page
        if (action.page < state.pageCurrent) calculateNewPage = state.pageCurrent -action.page

        return {
          ...state,
          isChangedByButtonNumber: true,
          pageCurrent: calculateNewPage,
          changePage: true
        }
      }
      case TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_CHANGE_ORDER: {
        const aux = { ...state.objFiltered }
        console.log(aux);
        console.log(action);
        if (action.direction !== 'none') {
          aux.sort = action.direction+"_"+action.field;
        } else {
          aux.sort = '';
        }
        const orderBy = {
          field: action.field,
          direction: action.direction
        }
        console.log('entro cesar');
        console.log(aux);

        return {
          ...state,
          orderBy: orderBy,
          changePage: false,
          // allUserLoaded: false,
          responseTransactionsList: null,
          objFiltered: aux,
          isSearching: true
        }
      }
      default:
        return state
  }
}

export default query;

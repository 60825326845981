import { LOGIN_ACTIONS, SAGA_GET_REQUEST_API, SAGA_RECEIVE_DATA, SAGA_GET_COMMENTS_BY_ID, SAGA_GET_RECEIVE_COMMENTS_BY_ID } from "../../constants/actions";
import { defaultValuesAuth } from "../../constants/constants";
import Transform from "../../utils/transform";

let defaultState = defaultValuesAuth;

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_USER_INFO: {
      const aux = { ...state.user };

      aux[action.id] = action.value;

      return {
        ...state,
        user: aux
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_CODE: {
      return {
        ...state,
        userCode: action.code
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_FIELDS_FILTEREDS: {
      const aux = { ...state.objFiltered };
      if ((action.id === 'binQuery' && aux.binQuery.length === 16) && aux.maskTypeQuery === "Enmascarada") {
        const result = Transform.encryptNumber(Number(aux.binQuery))
        aux[action.id] = result
      } 
       else if (action.id === "maskTypeQuery" && aux.binQuery.length === 16)  {
         const result = Transform.encryptNumber(Number(aux.binQuery))
         aux.binQuery = result
       } 
      else {
        aux[action.id] = action.value;
      }
      return {
        ...state,
        objFiltered: aux
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_SEND_CODE:
      return {
        ...state,
        isLoadingValidationCode: true
      }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_VALIDATE_FIELDS: {
      const result = action.result;
      return {
        ...state,
        validateFields: result
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALIDATE_FIELDS: {
      const field = action.field;
      const aux = { ...state.validateFields }
      if (field === 'all') {
        return {
          ...state,
          validateFields: defaultValuesAuth.validateFields
        }
      }
      aux[field] = (state.user && state.user[field] && state.user[field].length) > 0 ? false : true;
      return {
        ...state,
        validateFields: aux
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_USER: {
      return {
        ...state,
        isLoading: true
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_AUTH_LOGIN_USER: {
      const response = action.response;
      return {
        ...state,
        userResponseAuth: action.response,
        isLoading: false,
        isDisabled: (response && !response.success && response.authentication &&
          response.authentication.meta && response.authentication.meta.status && response.authentication.meta.status.message_ilgn && response.authentication.meta.status.message_ilgn[0].isDisabled) ?
          true : false,
        isLogged: (action && action.response && action.response.token) ? true : false
      }
    }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_PUT_LOGIN_CODE: {
      return {
        ...state,
        responseSendCodeChallenge: action.response,
        isLoadingValidationCode: false
      }
    }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATION_BY_CODE: {
 
      return {
        ...state,
        userResponseByCode: null
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES: {
 
      return {
        ...state,
        userResponseByCode: null
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALIDATION_CHALLENGE:
      return {
        ...state,
        responseSendCodeChallenge: null,
        userResponseByCode: null
      }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_VALIDATION_BY_CODE: {
      return {
        ...state,
        userResponseByCode: action.response,
        isLoggedByCode: true
      }
    }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_RESET:
      return {
        ...state,
        user: {},
        userResponseAuth: {},
        isLogged: false,
        isDisabled: (state.attempsTotal === 3) ? false : state.isDisabled,
        attempsTotal: (state.attemp === null) ? 0 : state.attempsTotal,
      }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_RESET_DISABLED:
      return {
        ...state,
        isDisabled: false
      }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_COUNTER_ATTEMPS: {
      return {
        ...state,
        attempsTotal: state.attempsTotal + action.attemp,
        attemp: action.attemp
      }
    }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_LOGOUT: {
      let dash = localStorage.getItem('dashboard-popup');
      localStorage.clear()
      localStorage.setItem('dashboard-popup', (dash ? dash : ''));
      return {
        ...state
      }
    }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_SHOW_PASSWORD:
      return {
        ...state,
        showPass: !state.showPass
      }

    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_PASSWORD:
      return {
        ...state,
        resetLoaded: true
      }
      
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_RESPONSE_RESET_PASSWORD:
      return {
        ...state,
        responseResetPassword: action.response,
        resetValided: true,
      }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES_RECOVERY_PASSWORD:
      return {
        ...state,
        responseResetPassword: {},
        resetLoaded: false,
        resetValided: false
      }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_ACQUIRERS_AVAILABLE:
      return {
        ...state,
        listAcquirersLoading: true,
        listAcquirersLoaded: false
      }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ACQUIRERS_AVAILABLE: {
      return {
        ...state,
        responseListAcquirers: action.response,
        listAcquirers: action.response.data,
        listAcquirersLoaded: true,
        listAcquirersLoading: false,
        onlyOneAcquirer: (action.response.data && action.response.data.length > 1) ? false: true
      }

    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESENT_CODE: {
      return {
        ...state,
        wentCodeLoaded: true,
        isReesent: true
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_RESPONSE_RESENT_CODE: {
      return {
        ...state,
        responseResentCode: action.response,
        wentCodeLoaded: false,
        isReesent: false
      }
    }
    case LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES_RESENT_CODE:
      return {
        ...state,
        responseResentCode: null
      }
    default:
      return state
  }
}

export default auth;
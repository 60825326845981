import { USERS_ACTIONS } from "../../constants/actions"

export const handleValuesUserFilter = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES,
    id,
    value
  }
}

export const getListUsers = (token, acquirerSelected, pageCurrentDashboard, objUsersFiltered) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_LIST_USERS,
    token,
    acquirerSelected,
    pageCurrentDashboard,
    objUsersFiltered
  }
}

export const receiveresponseGetListUsers = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS,
    response
  }
}


export const getUserInfo = (token, idUser, acquirerSelected) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_GET_INFO,
    token,
    idUser,
    acquirerSelected
  }
}

export const receiveresponseGetUserInfo = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO,
    response
  }
}

export const handleFieldsNewUser = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS,
    id,
    value
  }
}

export const sentInvitation = (newUser, token, acquirerSelected) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION,
    newUser,
    token,
    acquirerSelected
  }
}

export const receiveResponseSentInvitation = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION,
    response
  }
}

export const updateListUsers = (list) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_LIST_USERS,
    list
  }
}

export const resetValues = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES,
  }
}

export const getAssociateDetail = (token, id, pageCurrent) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL,
    token,
    id,
    pageCurrent
  }
}

export const receiveresponseGetAssociateDetail = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL,
    response
  }
}

export const nextPage = () => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_BACK_PAGE
  }
}


export const nextPageDashboard = () => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_NEXT_PAGE
  }
}

export const backPageDashboard = () => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_BACK_PAGE
  }
}


export const getInfoUserProfile = () => {
  return {
      type: USERS_ACTIONS.USER_ACTIONS_GET_INFO_PROFILE
  }
}


export const receiveResponseGetInfoUserProfile = () => {
  return {
      type: USERS_ACTIONS.USER_ACTIONS_RECEIVE_RESPONSE_GET_INFO_PROFILE
  }
}

export const cancelInvitation = (token, acquirerSelected, idUser) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION,
      token,
      acquirerSelected,
      idUser
  }
}
export const receiveResponseCancelInvitation = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION,
      response
  }
}

export const updateImage = (token, acquirerSelected, base64, idUser) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE,
      token,
      acquirerSelected,
      base64,
      idUser
  }
}
export const receiveResponseUpdateImage = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE,
      response
  }
}

export const resentInvitation = (token, acquirerSelected, idUser, role) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION,
      token,
      acquirerSelected,
      idUser,
      role
  }
}
export const receiveResponseResentInvitation = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION,
      response
  }
}

export const deleteUser = (token, acquirerSelected, idUser) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_DELETE_USER,
      token,
      acquirerSelected,
      idUser
  }
}
export const receiveResponseDeleteUser = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER,
      response
  }
}

export const updateUserInfo = (token, acquirerSelected, user) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO,
      token,
      acquirerSelected,
      user
  }
}
export const receiveResponseUpdateUserInfo = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO,
      response
  }
}

export const receiveResponseUpdateUserInfoProfile = (response) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO,
      response
  }
}

export const resetValuesIImageUploaded = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE
  }
}

export const handleValuesUpdateInfoUser = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER,
    id,
    value
  }
}

export const handleValuesUpdateInfoUserActive = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_ACTIVE,
    id,
    value
  }
}

export const goToPage = (token, id, pageCurrent) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE,
      token,
      id,
      pageCurrent
  }
}

export const goToPageDashboard = (token, acquirerSelected, pageCurrentDashboard, objUsersFiltered) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_GO_TO_PAGE,
      token,
      acquirerSelected,
      pageCurrentDashboard,
      objUsersFiltered
  }
}

export const handleValuesUpdatePasswordUI = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTION_HANDLE_UPDATE_PASSWORD_UI,
    id,
    value
  }
}

export const updateUserPassword = (token, acquirerSelected, user, user_key) => {
  return {
      type: USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD,
      token,
      acquirerSelected,
      user,
      user_key
  }
}

export const receiveUpdatePassword = (response) => {
  console.log(response);
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RECEIVE,
    response
  }
}

export const reserValuesUpdatePassword = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RESET_VALUES
  }
}

export const resetValuesUpdatePasswordForm = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD_RESET_VALUES_FORM
  }
}

export const deleteFilterSelected = (filters, token, page, acquirerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    acquirerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const changeOrder = (field, direction, token, acquirerSelected, pageCurrentDashboard, objUsersFiltered) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    acquirerSelected,
    pageCurrentDashboard,
    objUsersFiltered
  }
}
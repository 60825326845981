import { getLoginByCodeUser } from './../redux/actions/auth';

export class Helpers {
  static validateSession(token) {
    let result = false;
    
    if (token) {
      result = true;
    }
    return result;
  }
  static validateTokenExpired(token, dispatch) {
    if (token) {
     return dispatch(getLoginByCodeUser(token))
    }
    return null
  }
  static withSHA512(text) {
    const sha512 = require('sha512');
    const text_hashed = sha512(text).toString('hex');
    console.log(text_hashed);
    return text_hashed;
  }
  static buildExportURL(urlBase, required_data) {
    console.log('Operando_DOWNLOAD');
    const url = document.createElement('a');
    url.setAttribute('href', urlBase);
    let downloadURL = `${process.env.REACT_APP_API_EXPORT}${url.search}&required_data=${required_data}`;
    return downloadURL;
  }
}

export default Helpers;

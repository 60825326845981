import { HOME_ACTIONS, MERCHANTS_ACTIONS } from "../../constants/actions";
import { defaultValuesHome } from "../../constants/constants";

let defaultState = defaultValuesHome;

const home = (state = defaultState, action) => {
  switch (action.type) {
    case HOME_ACTIONS.HOME_ACTIONS_UPDATE_QUERIES:
      return {
        ...state,
        listQueries: action.list,
        isLoaded: true
      }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER:
      return {
        ...state,
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER: {
      let newArrayCurrencies = []
      let aux = { ...action.response }
      aux.currencies.forEach((curren, index) => {
        newArrayCurrencies.push({ iso_code: curren.iso_code, numeric_code: curren.numeric_code, /*byBrand: aux.brands[index].code,*/ description: curren.description });
      });

      return {
        ...state,
        isLoadedBrands: true,
        responseListBrands: action.response,
        brands: action.response.brands,
        currencies: newArrayCurrencies,
        currenciesByAcquirer: action.response.CurrenciesByAcquirer,
        protocols: action.response.protocols,
        transtype: (action.response.transtype) ? action.response.transtype : []
      }
    }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER:
      return {
        ...state
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES: {
      return {
        ...state,
        countries: action.response.paises,
        isLoadedCountries: true
      }
    }
    case MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILTERS_LIST_MERCHANTS:
      return {
        ...state,
      }
    case MERCHANTS_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING:
      return {
        ...state,
        listAcquirersIsLoading: true
      }
    case MERCHANTS_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADED:
      return {
        ...state,
        listAcquirersIsLoaded: true,
        listAcquirersIsLoading: false
      }
    case HOME_ACTIONS.HOME_ACTIONS_RESET_BRANDS:
      return {
        ...state,
        brands: []
      }
    case HOME_ACTIONS.HOME_ACTIONS_LIST_ACQUIRERS_LOADING:
      return {
        ...state,
        acquirerSelected: action.item
      }
    case HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE:
      return {
        ...state,
        userActiveIsLoaded: false,
        userActive: null
      }
    case HOME_ACTIONS.HOME_ACTION_HANDLE_UPDATE_INFO_USER_UI:
      const aux = { ...state.updateInfoUserUI }
      aux[action.id] = action.value
      return {
        ...state,
        updateInfoUserUI: aux
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE:
      return {
        ...state,
        isUpdatedUserInfo: false,
        userActive: action.response,
        userActiveIsLoaded: true,
        updateInfoUserUI: { 'name': action.response.name, 'lastname': action.response.lastname, 'country': action.response.country, 'countryCode': action.response.type_country, 'phone': action.response.mobile }
      }
    case HOME_ACTIONS.HOME_ACTION_UPDATE_USER_INFO_PROFILE:
      return {
        ...state,
        isUpdatedUserInfo: false,
        responseUpdatedUserInfo: null,
        isUpdatedUserInfoLoading: true

      }
    case HOME_ACTIONS.HOME_ACTIONS_RESET_VALUES_UPDATED:
      return {
        ...state,
        isUpdatedUserInfo: false
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO_PROFILE:
      return {
        ...state,
        responseUpdatedUserInfo: action.response,
        isUpdatedUserInfo: true,
        isUpdatedUserInfoLoading: false,
        userUpdatedProfileResult: (action.response && action.response.success) ? action.response.success : false,
        userUpdatedProfileResponse: (action.response && action.response.users.meta.status.message_ilgn) ? action.response.users.meta.status.message_ilgn[0].value : 'No se pudo actualizar su perfil'
      }
    case HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER:
      return {
        ...state,
      }
    case HOME_ACTIONS.HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER:
      return {
        ...state,
        responseAcquirerImageUpdated: action.response,
        changeImageIsCompleted: true
      }
    case HOME_ACTIONS.HOME_ACTIONS_RESET_PROFILE_RESET_VALUES:
      return {
        ...state,
        isUpdatedUserInfo: false
      }
    default:
      return state
  }
}

export default home;

export const ROUTE_GET = 'https://jsonplaceholder.typicode.com/posts/1/comments';
export const defaultValuesAuth = {
  isReesent: false,
  wentCodeLoaded: false,
  responseResentCode: null,
  listAcquirers: null,
  listAcquirersLoading: false,
  listAcquirersLoaded: false,
  onlyOneAcquirer: false,
  responseGetAuthUserInfo: {},
  responseListAcquirers: null,
  userEmailLoaded: false,
  user: {
      email: '',
      password: ''
  },
	userResponseAuth: {},
	userResponseByCode: null,
	responseSendCodeChallenge: null,
	isValidateCode: false,
	isLogged: false,
  isLoggedByCode: false,
  isLoading: false,
  isLoadingValidationCode: false,
	data: [],
	commentsById: [],
	validateFields: {
		email: false,
		password: false
	},
	userCode: null,
	attemp: 0,
	attempsTotal: 0,
  showPass: false,
  isDisabled: false,
  objFiltered: {
    fromDateQuery: '',
    toDateQuery: '',
    transactionTypeQuery: '',
    messageVersion:'',
    authResultQuery: '',
    nameQuery: '',
    codeQuery: '',
    numberOrderQuery: '',
    brandQuery: '',
    inclearTypeQuery: '',
    maskTypeQuery: '',
    cardNumberQuery: '',
    binQuery: '',
    currencyQuery: '',
    eciQuery: '',
    vciQuery: '',
    transIdQuery: '',
    dsTransIdQuery: '',
    acsTransIdQuery: '',
  },
  mailReset: '',
  responseResetPassword: {},
  resetLoaded: false,
  resetValided: false
}

export const defaultValuesHome = {
  responseAcquirerImageUpdated: null,
  changeImageIsCompleted: false,
  acquirerSelected: null,
  userActive: null,
  userActiveIsLoaded: false,
  listQueries: [],
  isLoaded: false,
  responseListBrands: {},
  isLoadedBrands: false,
  isLoadedCountries: false,
  brands: [],
  currencies: [],
  countries: [],
  protocols: [],
  listAcquirersIsLoaded: false,
  listAcquirersIsLoading: false,
  listAllAcquirers: [],
  transtype: []
}

export const defaultValuesQuery = {
  isChangedByButtonNumber: false,
  filtersSpanish: [
    {
      name: 'Fecha de inicio',
      state: false,
      propertyName: 'fromDateQuery'
    },
    {
      name: 'Fecha de fin',
      state: false,
      propertyName: 'toDateQuery'
    },
    {
      name: 'Tipo de transacción',
      state: false,
      propertyName: 'transactionTypeQuery'
    },
    {
      name: 'Versión',
      state: false,
      propertyName: 'messageVersionQuery'
    },
    {
      name: 'Resultado de autenticación',
      state: false,
      propertyName: 'authResultQuery'
    },
    {
      name: 'Nombre de comercio',
      state: false,
      propertyName: 'nameQuery'
    },
    {
      name: 'Código de comercio',
      state: false,
      propertyName: 'codeQuery'
    },
    {
      name: 'Número de pedido',
      state: false,
      propertyName: 'numberOrderQuery'
    },
    {
      name: 'Número de tarjeta',
      state: false,
      propertyName: 'cardNumberQuery'
    },
    {
      name: 'Marca',
      state: false,
      propertyName: 'brandQuery'
    },
    {
      name: 'Enmascarado',
      state: false,
      propertyName: 'maskTypeQuery'
    },
    {
      name: 'En claro',
      state: false,
      propertyName: 'inclearTypeQuery'
    },
    {
      name: 'BIN',
      state: false,
      propertyName: 'binQuery'
    },
    {
      name: 'LAST PAN',
      state: false,
      propertyName: 'lastPanQuery'
    },
    {
      name: 'Moneda',
      state: false,
      propertyName: 'currencyQuery'
    },
    {
      name: 'ECI',
      state: false,
      propertyName: 'eciQuery'
    },
    {
      name: 'VCI',
      state: false,
      propertyName: 'vciQuery'
    },
    {
      name: 'Trans ID',
      state: false,
      propertyName: 'transIdQuery'
    },
    {
      name: 'DS Trans ID',
      state: false,
      propertyName: 'dsTransIdQuery'
    },
    {
      name: 'ACS Trans ID',
      state: false,
      propertyName: 'acsTransIdQuery'
    }
  ],
  isSearching: false,
  transactionsList: null,
  responseTransactionsList: null,
  isLoadedTransactions: false,
  responseTransactionInfo: null,
  isLoadedGetInfoTransaction: false,
  pageCurrent: 1,
  fieldsFiltereds: {},
  changePage: false,
  searchingFilters: false,
  objFiltered: {
    fromDateQuery: '',
    toDateQuery: '',
    transactionTypeQuery: '',
    messageVersionQuery: '',
    authResultQuery: '',
    nameQuery: '',
    codeQuery: '',
    numberOrderQuery: '',
    brandQuery: '',
    inclearTypeQuery: '',
    maskTypeQuery: 'Enmascarada',
    cardNumberQuery: '',
    binQuery: '',
    currencyQuery: '',
    vciQuery: '',
    eciQuery: '',
    transIdQuery: '',
    dsTransIdQuery: '',
    acsTransIdQuery: '',
    lastPanQuery: '',
    sort: ''
  },
  exporting: false,
  responseExport: null,
  orderBy: {
    field: '',
    direction: ''
  }
}

export const defaultValuesUsers = {
  isChangedByButtonNumber: false,
  filtersSpanish: [
    {
      name: 'Fecha de inicio',
      state: false,
      propertyName: 'fromDateUser'
    },
    {
      name: 'Fecha de fin',
      state: false,
      propertyName: 'toDateUser'
    },
    {
      name: 'Nombre',
      state: false,
      propertyName: 'name'
    },
    {
      name: 'Apellido',
      state: false,
      propertyName: 'lastName'
    },
    {
      name: 'Correo',
      state: false,
      propertyName: 'email'
    },
    {
      name: 'Rol',
      state: false,
      propertyName: 'role'
    },
    {
      name: 'Estado',
      state: false,
      propertyName: 'state'
    }
  ],
  updateInfoUserUI: {
    name: '',
    lastName: '',
    countryCode: '',
    phone: ''
  },
  isUpdateInfoActiveUser: false,
  isChanging: false,
  newState: '',
  responseUserCancelInvitation: null,
  userIsCancelInvitation: false,
  userIsCancelInvitationLoading: false,
  responseUserReSentInvitation: null,
  userIsReSentInvitation: false,
  userIsReSentInvitationLoading: false,
  responseUserDelete: null,
  userIsDeleted: false,
  userIsDeletedLoading: false,
  responseUserImageUpdated: null,
  userImageIsChanged: false,
  userImageIsChanging: false,
  responseUpdatedUserInfo: null,
  isUpdatedUserInfo: false,
  isUpdatedUserInfoLoading: false,
  pageCurrentDashboard: 1,
  changePageDashboard: false,
  pageCurrent: 1,
  responseAssociateDetail: null,
  changePage: false,
  associateDetailIsloaded: false,
  objUsersFiltered: {
    fromDateUser: '',
    toDateUser: '',
    name: '',
    lastName: '',
    email: '',
    role: '',
    state: '',
    sort: ''
  },
  responseListAllUsers: null,
  listAllUsers: [],
  allUserLoaded: false,
  allUserLoading: false,
  userInfoLoaded: false,
  responseUserInfo: null,
  userInvited: {
    email: '',
    role: ''
  },
  responseSentInvitation: null,
  sendingInvitation: false,
  user: {
    email: '',
    state: '',
    role: '',
    dateRegistry: '',
    dateUpdated: '',
    lastUpdateBy: ''
  },
  userPasswordUI: {
    current: '',
    newPassword: '',
    againPassword: ''
  },
  userPasswordChanging: false,
  userPasswordUpdated: false,
  orderBy: {
    field: '',
    direction: ''
  }
}

export const defaultValuesMerchants = {
  isChangedByButtonNumber: false,
  responseCurrenciesByEditMerchant: null,
  currenciesUpdatedEditMerchant: null,
  currenciesUpdatedEditMerchantLoaded: false,
  showModalConfirmUpdate: false,
  updateLoading: false,
  filtersSpanish: [
    {
      name: 'Fecha de inicio',
      state: false,
      propertyName: 'fromDate'
    },
    {
      name: 'Fecha de fin',
      state: false,
      propertyName: 'toDate'
    },
    {
      name: 'Nombre de comercio',
      state: false,
      propertyName: 'merchantName'
    },
    {
      name: 'Código de comercio',
      state: false,
      propertyName: 'merchantCode'
    },
    {
      name: 'Estado',
      state: false,
      propertyName: 'state'
    },
    {
      name: 'Marca',
      state: false,
      propertyName: 'brand'
    }
  ],
  filtersBulkLoadSpanish: [
    {
      name: 'Fecha de inicio',
      state: false,
      propertyName: 'fromDate'
    },
    {
      name: 'Fecha de fin',
      state: false,
      propertyName: 'toDate'
    },
    {
      name: 'Nombre del archivo',
      state: false,
      propertyName: 'fileName'
    },
    {
      name: 'Estado',
      state: false,
      propertyName: 'stateProcess'
    }
  ],
  fieldsFiltereds: {},
  changePage: false,
  pageCurrent: 1,
  uploadFileCompleted: false,
  responseGetUrlFile: null,
  showModalStatusRegister: null,
  responseRegisterNewMerchantIsLoaded: false,
  responseRegisterNewMerchant: null,
  responseEditedMerchant: null,
  isLoadedMerchant: false,
  listCurrenciesSelecteds: [],
  currencySelectedByEdit: {},
  responseGetMerchantInfo: {},
  responseGetMerchantInfoIsLoaded: false,
  file: {},
  fields: {},
  responseFileSentToServer: null,
  responseGetUrlUploadFile: null,
  responseGetListWithAllFilters: null,
  getListWithAllFiltersIsLoaded: false,
  disabledUploadFileIsLoaded: true,
  responseUploadFile: null,
  uploading: false,
  uploadlingPercent: 0,
  uploadling: false,
  isCorrectFormat: null,
  sentFromInput: false,
  showForm: false,
  responseResultFilters: {},
  filter: {
    fromDate: '',
    toDate: '',
    merchantName: '',
    merchantCode: '',
    state: '',
    brand: ''
  },
  filtersBulkLoad: {
    fromDate: '',
    toDate: '',
    fileName: '',
    stateBatch: '',
    stateProcess: ''
  },
  merchantsLoaded: false,
  newRegister: {
    commerce_name: '',
    merchant_category_code: '',
    merchant_information: {
    },
    country: 'PER',
    notification_url: '',
    protocol:{},
    decoupled_status: 'N',
    decoupled_time: '',
    brands: [],
    date_registry: "",
		date_update: "",
    last_update_by: "",
    transtype: "",
  },
  allBrands: [],
  allCurrencs: [],
  brandsSelected: [],
  brandSelected: {
    position: null,
    code_commerce: "",
		code: "",
		name: "",
		enabled: false,
    version: "",
    whitelist_status: false,
		currencies: []
  },
  allCurrencies: {
    visa: [],
    mscd: [],
    dinc: [],
    amex: []
  },
  allCodes: {
    0: '',
    1: '',
    2: '',
    3: ''
  },
  currencySelected: {
    numeric_code: "",
    iso_code: "",
    enabled: false
  },
  currenciesSelecteds: [],
  isUpdatedWithBrands: false,
  fileUploaded: {
    file: {}
  },
  exporting: false,
  responseExport: null,
  orderBy: {
    field: '',
    direction: ''
  },
  orderBulkLoadBy: {
    field: '',
    direction: ''
  },
  pageCurrentBulkLoad: 1,
  changePageBulkLoad: false,
  isChangedByButtonNumberBulkLoad: false,
}
export const COUNTRIES = [
	{
		value: 'PERU'
	},
	{
		value: 'CHILE'
	},
	{
		value: 'MEXICO'
	},
	{
		value: 'USA'
	},
	{
		value: 'BOLIVIA'
	}
]

export const VERSIONS_PROTOCOL = [
	{
		value: '2.1.0'
	},
	{
		value: '2.2.0'
	}
]

export const SCHEMA_SECURITY = [
	{
		value: '2.1.0'
	},
	{
		value: '2.2.0'
	}
]

export const COMBO_TRANSTYPE = [
	{
    code: '01',
		value: 'Compra de bienes / servicios'
	},
	{
    code: '03',
		value: 'Comprobar aceptación'
  },
  {
    code: '10',
    value: 'Financiamiento de la cuenta'
  },
  {
    code: '11',
    value: 'Transacción de cuasi efectivo'
  },
  {
    code: '28',
    value: 'Activación y carga prepago'
  }
]

export const PAYMENTS_METHODS = [
	{
		selected: false,
    type: 'MSCD',
    name: 'mastercard',
    urlImg: 'mastercard',
    urlImgGray: 'mastercard-gray'
	},
	{
		selected: false,
    type: 'VISA',
    name: 'visa',
		urlImg: 'visa',
    urlImgGray: 'visa-gray'
	},
	{
		selected: false,
		type: 'DINC',
    name: 'dinner',
	  urlImg: 'dinner',
    urlImgGray: 'dinner-gray'
	},
	{
		selected: false,
		type: 'AMEX',
    name: 'amex',
		urlImg: 'amex',
    urlImgGray: 'amex-gray'
	}
]

export const columns = [
    {
      title: 'COMERCIO',
      width: 0,
      dataIndex: 'merchantName',
      key: 'merchantName',
      fixed: 'left',
    },
    {
      title: 'CODIGO COMERCIO',
      width: 200,
      dataIndex: 'merchantCode',
      key: 'merchantCode',
    },
    {
      title: 'NÚMERO DE PEDIDO',
      width: 205,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'NÚMERO DE TARJETA',
      width: 220,
      dataIndex: 'cardNumber',
      key: 'cardNumber',
    },
    {
      title: 'IMPORTE',
      width: 105,
      dataIndex: 'import',
      key: 'import',
    },
    {
      title: 'RESULTADO',
      width: 200,
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: 'ECI',
      width: 65,
      dataIndex: 'eci',
      key: 'eci',
    }
  ];

  export const data = [
    {
      key: '1',
      name: 'John Brow1n',
      age: 32,
      address: 'New York Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '2',
      name: 'Jim Gree2n',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '3',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '4',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '5',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '6',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '7',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '8',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '9',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '10',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '11',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '12',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '13',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '14',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '15',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '16',
      name: 'Jim GreeDSADSADASDn',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    }
  ];

  export const OPTIONS_RESULT_AUTH = [
    {
      label: 'Autenticación Denegada',
      value: 'Autenticación Denegada'
    },
    {
      label: 'Autenticación Exitosa',
      value: 'Autenticación Exitosa'
    },
    {
      label: 'Autenticación Fallida',
      value: 'Autenticación Fallida'
    },
    {
      label: 'Autenticación Incompleta',
      value: 'Autenticación Incompleta'
    },
    {
      label: 'Autenticación Informativa',
      value: 'Autenticación Informativa'
    },
	  {
      label: 'Comercio/BIN No Participa',
		  value: 'Comercio/BIN No Participa'
    },
    {
      label: 'No identificado',
      value: 'No identificado'
    },
	]

  export const BOOLEAN_OPTIONS = [
    {
      label: 'Si',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    },
	]

  export const OPTIONS_TRANSACTION_TYPE = [
    {
      label: 'Sin fricción',
      value: 'frictionless'
    },
    {
      label: 'Con fricción',
      value: 'friction'
    },
    {
      label: 'Desacoplada',
      value: 'decoupled'
    },
	]

  export const VALUES_VERSION = [
    {
      label: '2.1.0',
      value: '2.1.0'
    },
    {
      label: '2.2.0',
      value: '2.2.0'
    }
  ]

	export const OPTIONS_CURRENCY = [
		{
      label: 'Barbados Dollar',
			value: 'DOL'
		},
		{
      label: 'Barbados Dollar',
			value: 'DOL'
		},
		{
      label: 'Mexican Peso',
			value: 'MXN'
		},
    {
      label: 'Nuevo Sol',
      value: 'PEN'
    },
    {
      label: 'US Dollar',
      value: 'USD'
    }
	]

	export const OPTIONS_BRANDS = [
		{
      label: 'Diners Club',
			value: 'DINC'
		},
		{
      label: 'Mastercard',
			value: 'MSCD'
		},
		{
      label: 'American Express',
			value: 'AMEX'
		},
		{
      label: 'Visa',
			value: 'VISA'
		}
  ]
  
  export const COLUMNS_MERCHANTS_TABLE = [
    {
      title: 'NOMBRE',
      width: 0,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'CODIGO DE COMERCIO',
      width: 90,
      dataIndex: 'mcc',
      key: 'mcc',
    },
    {
      title: 'ESTADO',
      width: 50,
      dataIndex: 'version_protocol',
      key: 'version_protocol',
    },
    {
      title: 'MARCA',
      width: 50,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'FECHA Y HORA DE REGISTRO',
      width: 105,
      dataIndex: 'notificationURL',
      key: 'notificationURL',
    }
  ]

  export const RESPONSE_LIST_MERCHANTS = {
    "action": "list_merchant",
    "success": true,
    "data": [
        {
            "name": "Xiaomi.mi",
            "mcc": "C12345",
            "country": "022",
            "notificationURL": "https://www.visa.com.pe/",
            "version_protocol": "2.1.0",
            "brand": "visa"
        },
        {
          "name": "Comercio",
          "mcc": "C12346",
          "country": "026",
          "notificationURL": "https://www.visa.com.pe/",
          "version_protocol": "2.1.0",
          "brand": "Mastercard"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      }
    ],
    "merchant": {
        "meta": {
            "status": {
                "code": "00",
                "message_ilgn": [
                    {
                        "locale": "es_PE",
                        "value": "Exito al mostrar los comercios"
                    }
                ]
            }
        }
    }
}

export const STATES_FILTER = [
  {
    value: 'Afiliado',
    key: '02'
  },
  {
    value: 'Desafiliado',
    key: '01'
  }
]

export const STATES_FILTER_BULK = [
  {
    value: 'Terminado',
    key: '02'
  },
  {
    value: 'Error en Proceso',
    key: '01'
  }
]

export const RESET_OBJ_MERCHANTS = {
    id: {
      value:'',
      hidden:true
    },
    name: {
      value:'',
    },
    code:{
      value:'',
      type:'link',
      link:'/merchant/edit/123456'
    },
    status:{
      value:''
    },
    brand:{
      value:''
    },
    date:{
      value:''
    }
}

export const RESPONSE_DATA_LIST_TRANSACTIONS = {
	"action": "list_transaction",
	"success": true,
	"data": [
      {
      "nameecommerce": "Bitel Recargas Web",
      "merchant_key": "12345",
      "ds_trans_id": "228232216",
      "brand_number": "1234567*****1234",
      "amount": "1000.00",
      "autentication": "Tarjeta Vencida",
      "eci": 5,
    },
    {
      "nameecommerce": "CLaro",
      "merchant_key": "12345",
      "ds_trans_id": "228232216",
      "brand_number": "1234567*****1234",
      "amount": "7000.00",
      "autentication": "Tarjeta Vencida",
      "eci": 5,
    },
    {
      "nameecommerce": "Ripley",
      "merchant_key": "12345",
      "ds_trans_id": "228232216",
      "brand_number": "1234567*****1234",
      "amount": "5000.00",
      "autentication": "Tarjeta Vencida",
      "eci": 5,
    }
  ],
	"transaction": {
        "merchant_key": "12345",
		"meta": {
			"status": {
				"code": "00",
				"message_ilgn": [{
					"locale": "es_PE",
					"value": "Filtro realizado"
				}]
			}
		}
	}
}

export const RESPONSE_LIST_FILES_BULK_LOAD = {
  "action": "list_batch",
  "success": true,
  "data": [
      {
          "id_merchant_batch": "01",
          "upload_name": "0001_Merchant_2019120302.csv",
          "generate_name": "0001_Merchant_2019120302##.csv",
          "records_received": "300",
          "records_processed": "200",
          "state_batch": "00",
          "state_process": "00",
          "id_user": "0001",
          "date_time": "29-04-2019 17:30:10"
      },
      {
          "id_merchant_batch": "02",
          "upload_name": "0002_Merchant_2019120302.csv",
          "generate_name": "0002_Merchant_2019120302##.csv",
          "records_received": "300",
          "records_processed": "200",
          "state_batch": "00",
          "state_process": "00",
          "id_user": "0001",
          "date_time": "29-04-2019 17:30:10"
      },
      {
          "id_merchant_batch": "03",
          "upload_name": "0003_Merchant_2019120302.csv",
          "generate_name": "0003_Merchant_2019120302##.csv",
          "records_received": "300",
          "records_processed": "200",
          "state_batch": "00",
          "state_process": "00",
          "id_user": "0001",
          "date_time": "29-04-2019 17:30:10"
      }
  ],
    "info_batch": {
    "meta": {
      "status": {
        "code": "00",
        "message_ilgn": [{
          "locale": "es_PE",
          "value": "Filtro realizado con exito"
        }]
      }
    }
  }
}

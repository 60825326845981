import { put, takeLatest, call, all } from 'redux-saga/effects';
import { getData, getAssociateDetailUser, getAuthorizationUser, getAuthorizationByCode, putAuthorizationByCode, postRecoveryPasswordUser, postListAllMerchantsFiltereds, postRegisterMerchant, getListBrands, getListCountries, postUploadFile, getUrlUploadFiled, postSentAllFiltersList, getMerchantInfoByCode, putProcessFile, putEditMerchant, getListAllTransactionsFiltereds, getUrl, getFile, postSentFileToServer, getTransaction, getAcquirers, getListAllUsers, getUserInfoService, sentInvitationApi, reSentCode, putUpdateUserInfoProfile, putUpdateUserInfo, putUpdateUserInfoPassword, putUserImage, deleteUser, cancelInvitationApi, reSentInvitationApi, updateAcquirerPicture, exportAllTransactionsFiltereds, exportAllMerchantsFiltereds} from './services/services';
import { SAGA_GET_REQUEST_API, SAGA_GET_COMMENTS_BY_ID, LOGIN_ACTIONS, MERCHANTS_ACTIONS, HOME_ACTIONS, TRANSACTION_ACTIONS, USERS_ACTIONS } from './constants/actions';
import { receiveAuthLoginUser, receiveGetLoginByCodeUser, receiveResponseSendCode, receiveResponseResetPassword, receiveResponseListAcquirersByUser, receiveResponserReSendCode } from './redux/actions/auth';
import { receiveResponseResultFiltereds, receiveResponseRegisterMerchant, receiveResponseUploadFile, receiveResponseGetUrlUploadFile, receiveResponseSentALlFilters, getReceiveResponseMerchantInfo, receiveResponseprocessFile, receiveResponseEditMerchant, receiveResponseGenerateUrlToDownloadFile, receiveResponseDownloadFile, receiveResponseUpdateCurrenciesEditMerchant, receiveExportMerchants } from './redux/actions/merchants';
import { receiveResponseGetUserActive, receiveResponseListBrands, receiveResponseListCountries, receiveResponseUpdateAcquirerPictureUi, receiveResponseUpdateUserInfoProfile } from './redux/actions/home';
import { RESPONSE_LIST_FILES_BULK_LOAD } from './constants/constants';
import { receiveResponseSentFiltersListMerchants, receiveResponseGetInfoTransaction, receiveExportTransactions } from './redux/actions/transactions';
import { receiveresponseGetAssociateDetail, receiveresponseGetListUsers, receiveresponseGetUserInfo, receiveResponseDeleteUser, receiveResponseSentInvitation, receiveResponseUpdateImage, receiveResponseUpdateUserInfo, receiveUpdatePassword} from './redux/actions/users';

// AUTH

function* authUser(action) {
	try {
		const response = yield call(getAuthorizationUser, action.user);
		yield put(receiveAuthLoginUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* authUserWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_USER, authUser)
}

function* getAuthUser(action) {
	try {
		const response = yield call(getAuthorizationByCode, action.code);
		yield put(receiveGetLoginByCodeUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getAuthUserWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATION_BY_CODE, getAuthUser)
}

function* sendUserCode(action) {
	try {
		const response = yield call(putAuthorizationByCode, action.token, action.code);
		yield put(receiveResponseSendCode(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* sendUserCodeWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_SEND_CODE, sendUserCode)
}
// RECOVERY PASSWORD

function* recoveryPassword(action) {
	try {
		const response = yield call(postRecoveryPasswordUser, action.email);
		yield put(receiveResponseResetPassword(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* postRecoveryPasswordWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_PASSWORD, recoveryPassword)
}

// MERCHANTS
function* processFile(action) {
	try {
		const response = yield call(putProcessFile, action.token, action.id);
		yield put(receiveResponseprocessFile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* putProcessFileWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_PUT_BATCH, processFile)
}

function* listMerchants(action) {
	try {
		const response = yield call(postListAllMerchantsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, '');
		yield put(receiveResponseResultFiltereds(response))
	}
	catch (e) {
		console.log(e)
	}
}

// EXPORT MERCHANTS
function* exportMerchants(action) {
	console.log(action)
	try {
	  const response = yield call(exportAllMerchantsFiltereds, action.filters, action.token, action.acquirerSelected);
	  yield put(receiveExportMerchants(response))
	}
	catch (e) {
	  console.log(e)
	}
}

function* exportMerchantsWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EXPORT_FILE, exportMerchants)
}

function* postlistMerchantsWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_FILTER_LIST, listMerchants)
}

function* listMerchantsByButton(action) {
	try {
		const response = yield call(postListAllMerchantsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, '');
		yield put(receiveResponseResultFiltereds(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* postlistMerchantByButtonWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GO_TO_PAGE, listMerchantsByButton)
}

// DELETE FILTERS MERCHANT

function* deleteFiltersMerchants(action) {
	try {
		const response = yield call(postListAllMerchantsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, action.filterSelectedToDelete);
		yield put(receiveResponseResultFiltereds(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* deleteFiltersMerchantsWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DELETE_FILTERS, deleteFiltersMerchants)
}

function* getMerchantInfo(action) {
	try {
		const response = yield call(getMerchantInfoByCode, action.code, action.token, action.acquirerSelected);
		yield put(getReceiveResponseMerchantInfo(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getMerchantInfoByCodeWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_MERCHANT_INFO, getMerchantInfo)
}

 function* registerMerchant(action) {
 	try {
 		const response = yield call(postRegisterMerchant, action.merchant, action.token, action.acquirerSelected);
 		yield put(receiveResponseRegisterMerchant(response))
 	}
 	catch (e) {
 		console.log(e)
 	}
 }

 function* postRegisterMerchantWatcher(action) {
 	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_REGISTER_MERCHANT, registerMerchant)
 }

 function* editMerchant(action) {
	try {
		const response = yield call(putEditMerchant, action.editedMerchant, action.token, action.merchantCode, action.acquirerSelected);
		yield put(receiveResponseEditMerchant(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* putEditMerchantWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_EDIT_MERCHANT, editMerchant)
}


// HOME

function* listBrands(action) {
	try {
		const response = yield call(getListBrands, action.id, action.token, action.acquirerSelected);
		yield put(receiveResponseListBrands(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* listBrandsWatcher(action) {
	yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER, listBrands)
}

// BRANDS AND CURRENCIES EDIT MERCHANT

function* listBrandsMerchantEdit(action) {
	try {
		const response = yield call(getListBrands, action.id, action.token, action.acquirerSelected);
		yield put(receiveResponseUpdateCurrenciesEditMerchant(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* listBrandsMerchantEditWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPDATE_CURRENCIES_EDIT_MERCHANT, listBrandsMerchantEdit)
}

function* listCountries(action) {
	try {
		const response = yield call(getListCountries);
		yield put(receiveResponseListCountries(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* listCountriesByAcquirerWatcher(action) {
	yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER, listCountries)
}

// BULK LOAD

function* uploadFile(action) {
	try {
		const response = yield call(postSentFileToServer, action.token, action.file, action.fields, action.acquirerSelected, action.nameFile);
		yield put(receiveResponseUploadFile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* uploadFileWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_UPLOAD_FILE, uploadFile)
}

function* getUrlUploadFile(action) {
	try {
		const response = yield call(getUrlUploadFiled, action.token, action.acquirerSelected, action.nameFile);
		yield put(receiveResponseGetUrlUploadFile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getUrlUploadFileWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GET_URL_UPLOAD_FILE, getUrlUploadFile)
}

function* postSentAllFilters(action) {
  console.log(action);
	try {
		const response = yield call(postSentAllFiltersList, action.filters, action.token, action.acquirerSelected, action.page);
		yield put(receiveResponseSentALlFilters(response))
	}
	catch (e) {
		console.log(e)
		yield put(receiveResponseSentALlFilters(RESPONSE_LIST_FILES_BULK_LOAD))
	}
}

function* postSentAllFiltersWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_SENT_ALL_FILTERS, postSentAllFilters)
}

function* getListBulkLoadByNumber(action) {
  console.log(action);
	try {
		const response = yield call(postSentAllFiltersList, action.filters, action.token, action.acquirerSelected, action.page);
		yield put(receiveResponseSentALlFilters(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getListBulkLoadByNumberWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_BULK_LOAD_GO_TO_PAGE, getListBulkLoadByNumber)
}

// BULK LOAD GENERATE URL and DOWNLOAD

function* getUrlFileToDownload(action) {
	try {
		const response = yield call(getUrl, action.id, action.token, action.acquirerSelected);
		yield put(receiveResponseGenerateUrlToDownloadFile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getUrlFileToDownloadWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_GENERATE_URL_DOWNLOAD_FILE, getUrlFileToDownload)
}

function* getFileNow(action) {
	try {
		const response = yield call(getFile, action.id, action.token);
		yield put(receiveResponseDownloadFile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getFileNowWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_DOWNLOAD_FILE, getFileNow)
}

// TRANSACTIONS

function* getListTransactions(action) {
	try {
		const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, '');
		yield put(receiveResponseSentFiltersListMerchants(response))
	}
	catch (e) {
		console.log(e)
	}
}
// EXPORT TRANSACTIONS
function* exportTransactions(action) {
	console.log(action)
	try {
	  const response = yield call(exportAllTransactionsFiltereds, action.filters, action.token, action.acquirerSelected);
	  yield put(receiveExportTransactions(response))
	}
	catch (e) {
	  console.log(e)
	}
}

function* exportTransactionsWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_EXPORT_FILE, exportTransactions)
}

function* getSentAllTransactionsFilteredsWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_SENT_FILTERS_LIST_TRANSACTIONS, getListTransactions)
}


function* getListTransactionsByNumber(action) {
	try {
		const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, '');
		yield put(receiveResponseSentFiltersListMerchants(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getSentAllTransactionsByNumberFilteredsWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GO_TO_PAGE, getListTransactionsByNumber)
}

// DELETE FILTER TRANSACTIONS
function* getListTransactionsFiltered(action) {
	try {
		const response = yield call(getListAllTransactionsFiltereds, action.filters, action.token, action.page, action.acquirerSelected, action.filterSelectedToDelete);
		yield put(receiveResponseSentFiltersListMerchants(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getSentAllTransactionsFilteredsToDeleteWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_DELETE_FILTERS, getListTransactionsFiltered)
}

function* getTransactionInfo(action) {
	try {
		const response = yield call(getTransaction, action.id, action.token, action.acquirerSelected);
		yield put(receiveResponseGetInfoTransaction(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getTransactionInfoWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_GET_INFO, getTransactionInfo)
}

// LIST ACQUIRERS

function* getListAcquirers(action) {
	try {
		const response = yield call(getAcquirers, action.token);
		yield put(receiveResponseListAcquirersByUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getListAcquirersWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_ACQUIRERS_AVAILABLE, getListAcquirers)
}

// LIST USERS

function* getListUsers(action) {
	try {
		const response = yield call(getListAllUsers, action.token, action.acquirerSelected, action.pageCurrentDashboard, action.objUsersFiltered);
		yield put(receiveresponseGetListUsers(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getListUsersWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_LIST_USERS, getListUsers)
}

function* getListUsersByNumber(action) {
	try {
		const response = yield call(getListAllUsers, action.token, action.acquirerSelected, action.pageCurrentDashboard, action.objUsersFiltered);
		yield put(receiveresponseGetListUsers(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getListUsersByNumberWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_GO_TO_PAGE, getListUsersByNumber)
}

// USER INFO

function* getUserAllInfo(action) {
	try {
		const response = yield call(getUserInfoService, action.token, action.idUser, action.acquirerSelected);
		yield put(receiveresponseGetUserInfo(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getUserAllInfoWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GET_INFO, getUserAllInfo)
}

// USER INVITATION

function* invitationUser(action) {
	try {
		const response = yield call(sentInvitationApi, action.token, action.acquirerSelected, action.newUser);
		yield put(receiveResponseSentInvitation(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* postInvitationUserWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION, invitationUser)
}

// RE SENT CODE

function* reSentCodeSaga(action) {
	try {
		const response = yield call(reSentCode, action.email);
		yield put(receiveResponserReSendCode(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* postReSentCodeWatcher(action) {
	yield takeLatest(LOGIN_ACTIONS.LOGIN_ACTIONS_RESENT_CODE, reSentCodeSaga)
}

// GET ASSOCIATE DETAIL

function* getAssoiateDetail(action) {
	try {
		const response = yield call(getAssociateDetailUser, action.id, action.pageCurrent, action.token);
		yield put(receiveresponseGetAssociateDetail(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getAssoiateDetailWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL, getAssoiateDetail)
}

function* getAssoiateDetailByNumber(action) {
	try {
		const response = yield call(getAssociateDetailUser, action.id, action.pageCurrent, action.token);
		yield put(receiveresponseGetAssociateDetail(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getAssoiateDetailByButtonWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE, getAssoiateDetailByNumber)
}

//INFO USER ACTIVE
function* getUserActiveInfo(action) {
	try {
		const response = yield call(getUserInfoService, action.token, action.id, null, action.withOutAcquirerKey);
		yield put(receiveResponseGetUserActive(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getUserActiveInfoWatcher(action) {
	yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_GET_USER_ACTIVE, getUserActiveInfo)
}

//UPDATE USER
function* updateUserInfo(action) {
	try {
		const response = yield call(putUpdateUserInfo, action.token, action.acquirerSelected, action.user);
		yield put(receiveResponseUpdateUserInfo(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* updateUserInfoWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO, updateUserInfo)
}

// UPDATE PROFILE USER
function* updateUserInfoProfile(action) {
	try {
		const response = yield call(putUpdateUserInfoProfile, action.token, action.acquirerSelected, action.user, action.user_key);
		yield put(receiveResponseUpdateUserInfoProfile(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* updateUserInfoProfileWatcher(action) {
	yield takeLatest(HOME_ACTIONS.HOME_ACTION_UPDATE_USER_INFO_PROFILE, updateUserInfoProfile)
}

// UPDATE PASSWORD USER
function* updateUserPassword(action) {
	try {
		const response = yield call(putUpdateUserInfoPassword, action.token, action.acquirerSelected, action.user, action.user_key);
		yield put(receiveUpdatePassword(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* updateUserPasswordWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_RESET_PASSWORD, updateUserPassword)
}

//PUT USER IMAGE
function* updateUserImage(action) {
	try {
		const response = yield call(putUserImage, action.token, action.acquirerSelected, action.base64, action.idUser);
		yield put(receiveResponseUpdateImage(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* updateUserImageWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE, updateUserImage)
}

// DELETE USER
function* deleteUserSaga(action) {
	try {
		const response = yield call(deleteUser, action.token, action.acquirerSelected, action.idUser);
		yield put(receiveResponseDeleteUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* deleteUserWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_DELETE_USER, deleteUserSaga)
}

// CANCEL INVITATION
function* cancelInviationSaga(action) {
	try {
		const response = yield call(cancelInvitationApi, action.token, action.acquirerSelected, action.idUser);
		yield put(receiveResponseDeleteUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* cancelIntitationWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION, cancelInviationSaga)
}

// RESENT INVITATION
function* reSentInviationSaga(action) {
	try {
		const response = yield call(reSentInvitationApi, action.token, action.acquirerSelected, action.idUser, action.role);
		yield put(receiveResponseDeleteUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* reSentIntitationWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION, reSentInviationSaga)
}

// SENT PICTURE TO CHANGE ACQUIRER IMAGE
function* sentPicureAcquirerSaga(action) {
	try {
		const response = yield call(updateAcquirerPicture, action.token, action.acquirerSelected, action.idUser, action.base64);
		yield put(receiveResponseUpdateAcquirerPictureUi(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* sentPicureAcquirerWatcher(action) {
	yield takeLatest(HOME_ACTIONS.HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER, sentPicureAcquirerSaga)
}

// CHANGE ORDER LIST
// TRANSACTIONS
function* listTransactionsChangeOrderWatcher(action) {
	yield takeLatest(TRANSACTION_ACTIONS.TRANSACTION_ACTIONS_CHANGE_ORDER, getListTransactionsFiltered);
}

// MERCHANTS
function* listMerchantChangeOrderWatcher(action) {
	yield takeLatest(MERCHANTS_ACTIONS.MERCHANTS_ACTIONS_CHANGE_ORDER, listMerchants);
}

// USERS
function* listUsersChangeOrderWatcher(action) {
	yield takeLatest(USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER, getListUsers);
}

export default function* mySaga() {
	yield all([
    getListBulkLoadByNumberWatcher(),
    listUsersChangeOrderWatcher(),
    listMerchantChangeOrderWatcher(),
	exportTransactionsWatcher(),
	exportMerchantsWatcher(),
    listTransactionsChangeOrderWatcher(),
    getAssoiateDetailByButtonWatcher(),
    updateUserPasswordWatcher(),
		sentPicureAcquirerWatcher(),
		reSentIntitationWatcher(),
		cancelIntitationWatcher(),
		postlistMerchantByButtonWatcher(),
		updateUserImageWatcher(),
		deleteUserWatcher(),
    updateUserInfoWatcher(),
    updateUserInfoProfileWatcher(),
		getListUsersByNumberWatcher(),
		getAssoiateDetailWatcher(),
		postReSentCodeWatcher(),
		listBrandsMerchantEditWatcher(),
		authUserWatcher(),
		getAuthUserWatcher(),
		sendUserCodeWatcher(),
		postRecoveryPasswordWatcher(),
		postlistMerchantsWatcher(),
		postRegisterMerchantWatcher(),
		listBrandsWatcher(),
		listCountriesByAcquirerWatcher(),
		uploadFileWatcher(),
		getUrlUploadFileWatcher(),
		postSentAllFiltersWatcher(),
		getMerchantInfoByCodeWatcher(),
		putProcessFileWatcher(),
		putEditMerchantWatcher(),
		getSentAllTransactionsFilteredsWatcher(),
		getUrlFileToDownloadWatcher(),
		getFileNowWatcher(),
		getTransactionInfoWatcher(),
		getListAcquirersWatcher(),
		getListUsersWatcher(),
		getUserAllInfoWatcher(),
		postInvitationUserWatcher(),
		getSentAllTransactionsFilteredsToDeleteWatcher(),
		deleteFiltersMerchantsWatcher(),
		getSentAllTransactionsByNumberFilteredsWatcher(),
		getUserActiveInfoWatcher()
	]) 
}
